import { useEffect, memo, useState } from 'react';

import Service from "../services/service";
import mask from 'str-mask';
//import { toast } from 'react-toastify';
//import useSocket from "../hooks/useSocket";

/* estatisticas da pagina principal */
const StatisticsHome = (props) => {

  const [data, setData] = useState(props.data); 
  //const socket = useSocket(Service.api);
  
  // useEffect(() => {
  //   fetch(`${Service.api}/init`)
  //   .then(resp => resp?.json())
  //   .then(resp => setData(resp))
  //   .catch((e) => console.log('erro ao consultar api estatisticas da pagina principal -> ', e));
  // }, []);

  // useEffect(() => {

  //     if(socket){
  //       socket.on("newFindRegister", (data) => {
  //         handleMessage(data);
  //       });
        
  //       socket.on("newPedidoTelefone", (data) => {
  //         handleMessage(data);
  //       });
  //     }

  //   return () => {
  //       if(socket){
  //         socket.off("newPedidoTelefone");
  //         socket.off("newFindRegister");
  //       }
  //   }
  // }, [socket]);

  // const handleMessage = message => {

  //   let {title, body, icon} = Service.parseHandleMessage(message);

  //   toast(({ closeToast }) => <div className="d-flex align-items-sm-center">
  //       <img src={icon} width="30" alt="logotipo odete" />
  //       <div className="flex-fill text-dark px-2">
  //         <small className="m-0 d-block"><strong>{title}</strong></small>
  //         <small>{body}</small>
  //       </div>
  //     </div>, {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //   });
  // };

  return (
              <>
              <div className="d-flex mb-3 gap-3 justify-content-evenly py-3 border-top border-bottom">
                <div className="text-center">
                    <p className="lh display-4 m-0">{data?.reportsByFind ? Service.formatNumber(data?.reportsByFind) : '.'}</p>
                    <small className="d-block lh text-muted">pesquisas realizadas</small>
                </div>
                <div className="text-center">
                    <p className="lh display-4 m-0">{data?.resportsBySolicitacao ? data?.resportsBySolicitacao : '.'}</p>
                    <small className="d-block lh text-muted">faxinas agendadas</small>
                </div>
                <div className="text-center">
                    <p className="lh display-4 m-0">{data?.reportsByCityCount ? data?.reportsByCityCount : '.'}</p>
                    <small className="d-block lh text-muted">cidades atendidas</small>
                </div>
             </div>
              <div className="col text-center">
                  <small className="d-block lh-1">
                    último endereço pesquisado {data?.reportsByLastFind ? mask(data?.reportsByLastFind?.cep, {mask: '99999-999'}).viewValue : ''} <br />
                    {data?.reportsByLastFind ? data?.reportsByLastFind?.endereco : 'pesquisando endereço...'} <br />
                    {data?.reportsByLastFind ? data?.reportsByLastFind?.result === 0 ? 'nenhum diarista encontrada' : (data?.reportsByLastFind?.result === 1 ? '1 diarista encontrada' : `${data?.reportsByLastFind?.result} diaristas encontradas`) : 'diaristas encontradas...'}
                  </small>
              </div>
            </>
         )
}

export default memo(StatisticsHome);